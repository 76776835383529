<script setup lang="ts">
import { z } from "zod";
import type { Token } from "../entities/token";
const { fetchUserInfo, loggedIn } = useAuth();
const { $api } = useNuxtApp();
const loading = ref(false);
const cookieAccessToken = useCookie("auth:token", {
  maxAge: 2592000,
  path: "/",
});
const { t } = useI18n();

onMounted(() => {
  if (loggedIn) {
    navigateTo("/");
  }
});

definePageMeta({
  title: "Login",
  layout: "auth",
});

const providers = [
  {
    label: "Continue with Google",
    icon: "i-simple-icons-google",
    color: "white" as const,
    click: async () => {
      const url = useRuntimeConfig().public.googleProviderUrl;
      navigateTo(url, { external: true });
    },
  },
];

const fields = [
  {
    name: "email",
    type: "text",
    label: "Email",
    placeholder: "Enter your email",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Enter your password",
  },
];

const form = ref();

const schema = z.object({
  email: z.string().email(t("invalidEmail")),
  password: z.string().min(8, t("passwordMustBeAtLeast8characters")),
});

async function onSubmit(data: any) {
  try {
    loading.value = true;
    const token = await $api<Token>("/auth/login", {
      method: "POST",
      body: { ...data, device_name: "mac22" },
    });
    cookieAccessToken.value = token.access_token;
    await nextTick();
    await fetchUserInfo();
    navigateTo("/");
  } catch (error) {
    const result = convertBackendErrorToFormMsg(error);
    form.value.formRef.setErrors(result);
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
    <UAuthForm
      ref="form"
      :fields="fields"
      :schema="schema"
      :providers="providers"
      title="Welcome back"
      align="top"
      :ui="{ base: 'text-center', footer: 'text-center' }"
      :submit-button="{
        loading,
        trailingIcon: 'i-heroicons-arrow-right-20-solid',
        label: $t('continue'),
      }"
      @submit="onSubmit"
    >
      <template #icon>
        <div class="flex justify-center">
          <img class="w-32 h-32" src="/svgs/logo.svg" />
        </div>
      </template>
      <template #description>
        Don't have an account?
        <NuxtLink to="/register" class="text-primary font-medium">{{
          t("createANewAccount")
        }}</NuxtLink
        >.
      </template>

      <template #password-hint>
        <NuxtLink to="/forgot_password" class="text-primary font-medium"
          >Forgot password?</NuxtLink
        >
      </template>

      <template #footer>
        By signing in, you agree to our
        <NuxtLink to="/" class="text-primary font-medium"
          >Terms of Service</NuxtLink
        >.
      </template>
    </UAuthForm>
  </UCard>
</template>
